<template>
    <b-card class="mt-2" header-class="p-2">
        <template #header>
            <div class="d-flex justify-content-between" :id="task.id">
                <div class="w-50 mr-2">
                    <div>
                        <span class="small">Id: <b>{{ task.id }}</b></span>
                    </div>
                    <div>
						<span class="small">
							Название: <b>
							<a href="" @click.prevent="$router.push(`/tasks/${task.id}`)">{{ task.name }}</a>
						</b>
						</span>
                    </div>
                    <div v-if="isTaskClosed">
                        <span class="text-success small">Закрыта</span>
                    </div>
                    <div v-if="isTaskExpired & (getDaysToEnd !== 0)">
                        <span class="text-danger small">
                            <b>Просрочена:</b> {{ Math.abs(getDaysToEnd) }} {{ daysPlural(Math.abs(getDaysToEnd)) }}
                        </span>
                    </div>
                    <div v-if="!isTaskClosed && (getDaysToEnd >= 0 && getDaysToEnd <= 10)">
                        <span class="text-info small" v-if="getDaysToEnd === 0">
                            <b>Сегодня</b>
                        </span>
                        <span class="text-warning small" v-else>
                            <b>Осталось {{ getDaysToEnd }} {{ daysPlural(getDaysToEnd) }}</b>
                        </span>
                    </div>
                    <div v-if="task.building_process">
                        <span class="small">Объект: <b>{{ getObjectName(task) }}</b></span>
                    </div>
                </div>
                <div class="w-50">
                    <div>
                        <span class="small">
                                Постановщик:
                            <b v-html="getResponsibleName(task.Author)"></b>
                        </span>
                    </div>
                    <div>
                        <span class="small">
                            Ответственный: <b v-html="getResponsibleName(task.ResponsiblePerson)"></b>
                        </span>
                    </div>
<!--                    <div>-->
<!--                        <span class="small">-->
<!--                            Создана: <b>{{ $moment(task.createdAt).format('YYYY-MM-DD (dd) HH:mm') }}</b>-->
<!--                        </span>-->
<!--                    </div>-->
                    <div v-if="task.endDate">
                        <span class="small">
                            Дата окончания: <b>{{ $moment(task.endDate).format('DD.MM.YYYY (dd) HH:mm') }}</b>
                        </span>
                    </div>
                    <div v-if="task.cronHuman">
                        <span class="small">
                            Дата повтора: <b>{{ task.cronHuman }}</b>
                        </span>
                    </div>
                </div>
            </div>
        </template>
        {{ task.description }}
    </b-card>
</template>

<script>
import daysPlural from "@/utils/daysPlural";
export default {
    name: "TaskShortItem",
    props: {
        task: Object
    },
    computed: {
        objects() {
            return this.$store.state.objectsInProcess;
        },
        users() {
            return this.$store.state.tasksManagement.shortUsers;
        },
        isTaskClosed() {
            return this.task.status === 1;
        },
        isTaskExpired() {
            const {task, isTaskClosed, $moment} = this;
            return (task.endDate && !isTaskClosed) ? $moment().isAfter(task.endDate) : false;
        },
        getDaysToEnd() {
            const {task, isTaskClosed, $moment} = this;
            if (task.endDate && !isTaskClosed) {
                return $moment(task.endDate).diff($moment(), 'days');
            } else if (task.cronDateFormat) {
                return $moment(task.cronDateFormat).diff($moment(), 'days');
            } else {
                return -1;
            }
        }
    },
    methods: {
        getObjectName(task) {
            const {building_process} = task;
            const object = this.objects.find(el => el.building_process_id === building_process);
            return object?.object_name || task.Building?.bo?.name || ``;
        },
        getResponsibleName(user) {
            return user ?
                `<a href="https://t.me/+${user.phone}" target="_blank">${user.first_name} ${user.last_name}</a>`
                : ``;
        },
        daysPlural
    },
    mounted() {
        if (this.$route.hash === `#${this.task.id}`) {
            this.$emit(`scrollToMe`, this.task);
        }
    }
}
</script>

<style scoped>

</style>