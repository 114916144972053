<template>
    <div>
        <TasksNavbar class="mb-2"
                     :sortOptions="sortOptions"
                     :sortBy="sortBy"
                     :sortDesc="sortDesc"
                     :filter="filter"
                     @sortByChange="onSortByChange"
                     @sortDescChange="onSortDescChange"
                     @filterChange="onFilterChange"
        />
        <b-tabs card v-model="selectedTab">
            <b-tab title="Поручено мне" ref="myTask">
                <TaskShortItem v-for="(task, i) in responsibleTasks" :task="task" :key="i" :id="`id_${task.id}`"
                               :ref="`ref_${task.id}`" @scrollToMe="scrollToElement"/>
            </b-tab>
            <b-tab title="Поручил я" ref="assign">
                <TaskShortItem v-for="(task, i) in authorTasks" :task="task" :key="i" :id="`id_${task.id}`"
                               :ref="`ref_${task.id}`" @scrollToMe="scrollToElement"/>
            </b-tab>
            <b-tab title="Помогаю" ref="help">
                <TaskShortItem v-for="(task, i) in executorTasks" :task="task" :key="i" :id="`id_${task.id}`"
                               :ref="`ref_${task.id}`" @scrollToMe="scrollToElement"/>
            </b-tab>
            <b-tab title="Задачи себе" ref="mySelf">
                <TaskShortItem v-for="(task, i) in mySelfTasks" :task="task" :key="i" :id="`id_${task.id}`"
                               :ref="`ref_${task.id}`" @scrollToMe="scrollToElement"/>
            </b-tab>
            <b-tab title="Всех пользователей" v-if="isAdmin" ref="all">
                <TaskShortItem v-for="(task, i) in sorted(filteredTasks)" :task="task" :key="i" :id="`id_${task.id}`"
                               :ref="`ref_${task.id}`" @scrollToMe="scrollToElement"/>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import TasksNavbar from "@/views/Tasks/TasksNavbar/TasksNavbar";
import TaskShortItem from "@/views/Tasks/TaskShortItem/TaskShortItem";

export default {
    name: "Tasks",
    components: {
        TasksNavbar,
        TaskShortItem
    },
    computed: {
        isAdmin() {
            return this.$store.getters.isAdmin;
        },
        currentUser() {
            return this.$store.getters.currentUser;
        },
        objects() {
            return this.$store.state.objectsInProcess;
        },
        users() {
            return this.$store.state.tasksManagement.shortUsers;
        },
        authorTasks() {
            const {filteredTasks, currentUser, sorted} = this;
            return sorted(filteredTasks.filter(el => el.author === currentUser.userId));
        },
        responsibleTasks() {
            const {filteredTasks, currentUser, sorted} = this;
            return sorted(filteredTasks.filter(el => el.responsible_person === currentUser.userId));
        },
        executorTasks() {
            const {filteredTasks, currentUser, sorted} = this;
            return sorted(filteredTasks.filter(el => el.executors.some(ex => ex.user_id === currentUser.userId)));
        },
        mySelfTasks() {
            const {filteredTasks, currentUser, sorted} = this;
            return sorted(filteredTasks.filter(el => (el.author === currentUser.userId) && el.responsible_person === currentUser.userId));
        },
        filteredTasks() {
            const {filter, tasks} = this;
            if (!filter || filter.length < 2) {
                return tasks;
            }
            return tasks.filter(el => {
                const {Author, Building, description, name, executors, ResponsiblePerson} = el;
                let commonString = name;
                if (Author) {
                    commonString += Author.first_name;
                    commonString += Author.last_name;
                }
                if (ResponsiblePerson) {
                    commonString += ResponsiblePerson.first_name;
                    commonString += ResponsiblePerson.last_name;
                }
                if (Building) {
                    commonString += Building.bo.name;
                }
                if (description) {
                    commonString += description;
                }
                if (executors.length) {
                    executors.forEach(ex => {
                        commonString += ex.first_name;
                        commonString += ex.last_name;
                    })
                }
                return commonString.toLowerCase().includes(filter.toLowerCase());
            })
        },
        selectedTab: {
            get() {
                return this.$store.state.tasksManagement.selectedTab;
            },
            set(v) {
                this.$store.commit(`tasksSetSelectedTab`, v);
            }
        },
        filter: {
            get() {
                return this.$store.state.tasksManagement.filter;
            },
            set(v) {
                this.$store.commit(`setFilter`, v);
            }
        },
        sortOptions() {
            return this.$store.state.tasksManagement.sortOptions;
        },
        sortBy() {
            return this.$store.state.tasksManagement.sortBy;
        },
        sortDesc() {
            return this.$store.state.tasksManagement.sortDesc;
        }
    },
    data() {
        return {
            tasks: [],
            scrolled: false
        }
    },
    mounted() {
        this.updateCurrentTasks();
        if (!this.objects.length) {
            this.$store.dispatch(`getObjectsInProcess`);
        }
        if (!this.users.length) {
            this.$store.dispatch(`getShortUsers`).then(users => this.$store.commit(`setShortUsers`, users));
        }
        document.addEventListener("visibilitychange", () => {
            if (!document.hidden) {
                this.updateCurrentTasks();
            }
        });
    },
    methods: {
        updateCurrentTasks() {
            this.$store.dispatch(`getTasks`)
                .then(res => this.tasks = res);
        },
        onSortByChange(v) {
            this.$store.commit(`tasksSetSortBy`, v);
        },
        onSortDescChange(v) {
            this.$store.commit(`tasksSetSortDesc`, v);
        },
        onFilterChange(v) {
            this.filter = v;
        },
        sorted(data) {
            const {sortDesc, sortBy, $_} = this;
            const isSortByDate = sortBy === `endDate`;
            const sortField = isSortByDate ? `sortDate` : sortBy;
            if (isSortByDate) {
                data = data.map(el => {
                    el.sortDate = el.endDate || el.cronDateFormat;
                    return el;
                })
            }
            const empty = data.filter(el => !el[sortField]);
            const hasValue = data.filter(el => el[sortField]);
            const activeSorted = $_.sortBy(hasValue.filter(el => el.status === 0), sortField);
            const closedSorted = $_.sortBy(hasValue.filter(el => el.status === 1), sortField);
            return sortDesc
                ? activeSorted.reverse().concat(empty, closedSorted)
                : activeSorted.concat(empty, closedSorted.reverse());
        },
        scrollToElement(task) {
            const tabs = [`myTask`, `assign`, `help`, `mySelf`, `all`];
            const activeTab = this.$refs[tabs[this.selectedTab]];
            for (let i = 0; i < activeTab.$children.length; i++) {
                if (activeTab.$children[i].$attrs.id === `id_${task.id}`) {
                    this.$nextTick(() => {
                        if (!this.scrolled) {
                            activeTab.$children[i].$el.scrollIntoView({behavior: "smooth", block: "center"});
                        }
                        this.scrolled = true;
                    })
                }
            }
        }
    }
}
</script>