<template>
    <div :class="isMobile ? 'mobile-container' : ''">
        <nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow" v-if="showNavBar">
            <router-link class="navbar-brand col-sm-3 col-md-2 mr-0" to="/">{{ `АЭРОДОРСТРОЙ`.toUpperCase() }}
            </router-link>
            <ul class="navbar-nav px-3">
                <!--                <span class="text-light">{{ user.first_name}} {{ user.last_name}}</span>-->
                <div class="d-flex justify-content-between">
                    <span class="text-white px-2">Действующие</span>
                    <b-form-checkbox
                        name="check-button"
                        switch
                        v-model="showOnlyArchive"
                    >
                    </b-form-checkbox>
                  <span class="text-white px-2">Архив</span>
                  <li class="nav-item text-nowrap d-flex ml-3" v-if="isAuthorized">
                        <a class="nav-link p-0" href="#" @click="logout">Выйти</a>
                    </li>
                </div>
            </ul>
        </nav>
        <div class="container-fluid">
            <div class="row">
                <nav class="col-md-2 d-none d-md-block bg-light sidebar" v-if="isAuthorized">
                    <div class="sidebar-sticky">
                        <b-button
                            @click="$router.push(`/create-object-wizard`)"
                            size="sm"
                            variant="info"
                            v-if="$can('create', 'objectsInProcess')"
                            class="mx-2 mb-2"
                        >
                            Мастер создания объекта
                        </b-button>
                        <ul class="nav flex-column">
                            <li class="nav-item" v-if="$can('read', 'users')">
                                <router-link to="/users" class="nav-link">
                                    <i class="fas fa-users"></i>
                                    Сотрудники
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="$can('read', 'systemUsers')">
                                <router-link to="/systemUsers" class="nav-link">
                                    <i class="fas fa-users-cog"></i>
                                    Учетные записи в системе
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="$can('read', 'usersGroups')">
                                <router-link to="/user-groups" class="nav-link">
                                    <i class="fas fa-user-tag"></i>
                                    Группы сотрудников
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="$can('read', 'objects')">
                                <router-link to="/objects" class="nav-link">
                                    <i class="fas fa-city"></i>
                                    Строительные объекты
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="$can('read', 'objectsInProcess')">
                                <router-link to="/objects-in-process" class="nav-link">
                                    <i class="fas fa-igloo"></i>
                                    Строительные объекты в {{ showOnlyArchive ? `архиве` : `работе` }}
                                </router-link>
                            </li>
                            <li class="nav-item d-flex" v-if="$can('read', 'objectsInProcess')">
                                <span class="nav-link w-100 d-flex justify-content-between" v-b-toggle.works-collapse>
                                    <div>
                                       <i class="fas fa-tools"></i>
                                        Работы
                                    </div>
                                    <div>
                                       <b-icon v-if="isVisibleWorksCollapse" icon="chevron-bar-up"></b-icon>
                                       <b-icon v-else icon="chevron-bar-down"></b-icon>
                                    </div>
                                </span>
                            </li>
                            <b-collapse id="works-collapse" class="mt-2" v-model="isVisibleWorksCollapse">
                                <b-card body-class="p-0">
                                    <li class="nav-item" v-if="$can('read', 'targets')">
                                        <router-link to="/targets" class="nav-link px-4">
                                            <i class="fas fa-bullseye"></i>
                                            Объемы работ по договору
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="$can('read', 'completedWorks')">
                                        <router-link to="/completed-works" class="nav-link px-4">
                                            <i class="fas fa-calendar-check"></i>
                                            Выполненные работы
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="$can('read', 'workGroups')">
                                        <router-link to="/work-groups" class="nav-link px-4">
                                            <i class="fas fa-tools"></i>
                                            Группы работ
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="$can('read', 'workTypes')">
                                        <router-link to="/work-types" class="nav-link px-4">
                                            <i class="fas fa-tools"></i>
                                            Виды работ
                                        </router-link>
                                    </li>
                                </b-card>
                            </b-collapse>
                            <li class="nav-item d-flex" v-if="$can('read', 'materials')">
                                <span class="nav-link w-100 d-flex justify-content-between"
                                      v-b-toggle.materials-collapse>
                                    <div>
                                        <i class="fas fa-clipboard-list"></i>
                                        Материалы
                                    </div>
                                    <div>
                                       <b-icon v-if="isVisibleMaterialsCollapse" icon="chevron-bar-up"></b-icon>
                                       <b-icon v-else icon="chevron-bar-down"></b-icon>
                                    </div>
                                </span>
                            </li>
                            <b-collapse id="materials-collapse" class="mt-2" v-model="isVisibleMaterialsCollapse">
                                <b-card body-class="p-0">
                                    <li class="nav-item" v-if="$can('read', 'materialTargets')">
                                        <router-link to="/material-targets" class="nav-link px-4">
                                            <i class="fas fa-bullseye"></i>
                                            Объемы материалов по договору
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="$can('read', 'materialUsing')">
                                        <router-link to="/material-using" class="nav-link px-4">
                                            <i class="fas fa-tasks"></i>
                                            Использование материалов по договору
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="$can('read', 'materials')">
                                        <router-link to="/materials" class="nav-link px-4">
                                            <i class="fas fa-list-ol"></i>
                                            Справочник материалов
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="$can('read', 'materialGroups')">
                                        <router-link to="/material-groups" class="nav-link px-4">
                                            <i class="fas fa-layer-group"></i>
                                            Группы материалов
                                        </router-link>
                                    </li>
                                </b-card>
                            </b-collapse>
                            <li class="nav-item" v-if="$can('read', 'workShifts')">
                                <router-link to="/work-shifts" class="nav-link">
                                    <i class="far fa-calendar-alt"></i>
                                    Рабочие смены
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="$can('read', 'fuelLimits')">
                                <router-link to="/fuel-limits" class="nav-link">
                                    <i class="fas fa-gas-pump"></i>
                                    Лимиты по топливу
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="$can('read', 'usedFuels')">
                                <router-link to="/used-fuels" class="nav-link">
                                    <i class="fas fa-truck-pickup"></i>
                                    Израсходованное топливо
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="$can(`read`, `chartsPage`)">
                                <router-link to="/charts" class="nav-link">
                                    <i class="fas fa-chart-bar"></i>
                                    Графики
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="$can(`read`, `gallery`)">
                                <router-link to="/gallery" class="nav-link">
                                    <i class="fas fa-image"></i>
                                    Галерея
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="$can(`read`, `reports-management`)">
                                <router-link to="/reports-management" class="nav-link">
                                    <i class="fas fa-bell"/>
                                    Управление отчетами с уведомлениями
                                </router-link>
                            </li>
                            <li class="nav-item" v-if="$can(`read`, `alerts-management`)">
                                <router-link to="/alerts-management" class="nav-link">
                                    <i class="fas fa-bell"/>
                                    Управление уведомлениями по действиям
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/tasks" class="nav-link">
                                    <i class="fas fa-bell"/>
                                    Задачи
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </nav>
                <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4 main-parent">
                    <router-view :key="$route.fullPath"/>
                </main>
            </div>
        </div>
        <create-material-target></create-material-target>
        <error-modal></error-modal>
    </div>
</template>

<script>
import CreateMaterialTarget from "./Materials/Targets/CreateTarget/CreateMaterialTarget.vue";
import ErrorModal from "../components/Modals/ErrorModal.vue";

export default {
    name: "MainComponent",
    components: {
        CreateMaterialTarget,
        ErrorModal
    },
    computed: {
        user() {
            return this.$store.getters.currentUser;
        },
        isAuthorized() {
            return this.$store.getters.isAuthorized;
        },
        isMobile() {
            return this.$store.state.isMobile;
        },
        showOnlyArchive: {
            get() {
                return this.$store.state.showOnlyArchive;
            },
            set(val) {
                const currentPath = this.$route.path;
                this.$store.commit(`setShowOnlyArchive`, val);
                this.$router.push(`${currentPath}?archive=${val}`);
            }
        },
        showNavBar() {
            return this.isAuthorized && !this.isMobile;
        }
    },
    data() {
        return {
            isVisibleMaterialsCollapse: false,
            isVisibleWorksCollapse: false
        }
    },
    methods: {
        logout() {
            this.$store.commit('removeToken');
            this.$router.push('/login');
        }
    }
}
</script>

<style scoped lang="stylus">
.logo-img
    width 50px
    height auto

.nav-item:hover
    background-color khaki

.sidebar-sticky
    .router-link-active
        background-color #9be7ac

.work-group
    margin-left 3px
    font-size 14px

.mobile-container
    .main-parent
        padding-top 0 !important


</style>